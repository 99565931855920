<template>
  <v-form ref="formStep1" lazy-validation @submit.prevent="stepValidate()">
    <v-stepper v-model="step" vertical class="elevation-0">
      <v-stepper-step
        step="1"
        :complete="stepVisibilityCompleted"
        :color="stepVisibilityCompleted ? 'success' : 'primary'"
        @click="step = 1"
        class="cursor-pointer"
        :data-intercom-target="'create_campaign.step1_completed_' + (stepVisibilityCompleted ? 'yes' : 'no')"
      >
        <div @click="step = 1">
          <div>Campaign type</div>
          <small v-if="stepVisibilityCompleted"
            >Type {{ value.hidden ? "Hidden" : "Private" }}</small
          >
        </div>
      </v-stepper-step>
      <v-stepper-content step="1">
        <v-container fluid grid-list-xl class="p-0">
          <v-layout wrap>
            <v-flex sm6 xs12>
              <v-card flat class="bordered">
                <div class="text-right p-2">
                  <information-dialog
                    title="Public campaign"
                    subtitle="Visible to all influencers"
                    text="This campaign can be seen by all influencers, and those who meet your requirements can apply to it, and you can invite those influencers as well."
                  > 
                    <v-icon small>fal fa-question-circle</v-icon>
                  </information-dialog>
                </div>

                <v-card-text>
                  <div class="text-center">
                    <v-icon large>fal fa-eye</v-icon>

                    <div class="headline mt-2">
                      Public
                    </div>

                    <v-btn
                      small
                      data-intercom-target="create_campaign.btn.public_campaign"
                      :color="value.hidden !== false ? 'primary' : 'success'"
                      :outlined="
                        value.hidden !== null ? value.hidden === true : null
                      "
                      class="mt-3"
                      @click="
                        value.hidden = false;
                        step = 2;
                      "
                    >
                      {{ value.hidden !== false ? "Select" : "Selected" }}
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex sm6 xs12>
              <v-card flat class="bordered">
                <div class="text-right p-2">
                  <information-dialog
                    title="What is a private campaign??"
                    subtitle="Only visible with invitation"
                  >
                    <template v-slot:body>
                      <p>
                        This campaign can't be seen by the influencers, only the
                        ones you choose to invite for it.
                      </p>
                      This campaign type is good, if you for example:
                      <ul>
                        <li>
                          Want to make a campaign more general over time
                        </li>
                        <li>
                          Want a campaign with specific influencers, and don't want
                          to spend time rejecting applications from other influencers
                        </li>
                        <li>
                          Want to create a campaign with other benefits than
                          other active campaign
                        </li>
                      </ul>
                    </template>
                    <template v-slot:default>
                      <v-icon small>fal fa-question-circle</v-icon>
                    </template>
                  </information-dialog>
                </div>

                <v-card-text>
                  <div class="text-center">
                    <v-icon large>fal fa-eye-slash</v-icon>

                    <div class="headline mt-2">
                      Private
                    </div>

                    <v-btn
                      small
                      :color="value.hidden !== true ? 'primary' : 'success'"
                      :outlined="value.hidden !== true ? true : null"
                      class="mt-3"
                      @click="
                        value.hidden = true;
                        step = 2;
                      "
                    >
                      {{ value.hidden !== true ? "Vælg" : "Valgt" }}
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-stepper-content>
      <v-stepper-step
        step="2"
        :complete="stepMinimumFollowersCompleted"
        :color="stepMinimumFollowersCompleted ? 'success' : 'primary'"
        @click="step = 2"
        class="cursor-pointer"
      >
        <div class="cursor-pointer" @click="step = 2">
          <div>Min. follower count</div>
          <small
            v-if="stepMinimumFollowersCompleted && value.min_followers > 0"
            >{{ value.min_followers | local_numbers }}</small
          >
          <small
            v-if="stepMinimumFollowersCompleted && value.min_followers === 0"
            >No limit</small
          >
        </div>
      </v-stepper-step>
      <v-stepper-content step="2">
        <v-container fluid grid-list-xl class="p-0">
          <v-layout wrap>
            <v-flex
              v-for="followerItem in followerItems"
              md4
              sm6
              xs12
              :key="followerItem"
            >
              <v-card flat class="flex-card bordered" height="100%">
                <v-card-text class="text-center pb-0 grow">
                  <v-icon large>{{
                    followerItem === 0 ? "fal fa-user-slash" : "fal fa-users"
                  }}</v-icon>

                  <div class="mt-2">
                    <template v-if="followerItem === 0"
                      >No limit</template
                    >
                    <template v-else>{{
                      followerItem | local_numbers
                    }}</template>
                  </div>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn
                    small
                    :data-intercom-target="'create_campaign.btn.min_follower_' + followerItem"
                    :color="
                      value.min_followers === followerItem
                        ? 'success'
                        : 'primary'
                    "
                    :outlined="
                      followerItem !== 0 &&
                        value.min_followers !== followerItem
                    "
                    @click="
                      value.min_followers = followerItem;
                      step = 3;
                    "
                  >
                    {{
                      value.min_followers === followerItem ? "Valgt" : "Vælg"
                    }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-stepper-content>
      <v-stepper-step
        step="3"
        :complete="stepCategoryCompleted"
        :color="stepCategoryCompleted ? 'success' : 'primary'"
        @click="step = 3"
        class="cursor-pointer"
      >
        <div class="cursor-pointer" @click="step = 3">
          <div>Category</div>
          <small
            v-if="
              stepCategoryCompleted && categories.length && value.category > 0
            "
          >
            {{
              categories.filter(item => item.id === value.category.id)
                .display_name
            }}
          </small>
        </div>
      </v-stepper-step>
      <v-stepper-content step="3" data-intercom-target="create_campaign.step1_3">
        <div class="px-2">
          <channel-category-select
            :returnObject="false"
            :hasIcons="true"
            :multiple="false"
            v-if="value.category"
            v-model="value.category"
            class="mt-4"
            data-intercom-target="create_campaign.select.category"
            @input="step = 4"
          ></channel-category-select>
        </div>
      </v-stepper-content>
      <v-stepper-step
        step="4"
        :complete="stepLandingCompleted"
        :color="stepLandingCompleted ? 'success' : 'primary'"
        @click="step = 4"
        class="cursor-pointer"
      >
        <div class="cursor-pointer" @click="step = 4">
          <div>Landing page</div>
        </div>
      </v-stepper-step>
      <v-stepper-content step="4" data-intercom-target="create_campaign.step1_4">
        <!-- Landing page link -->
        <div class="px-3">
          <v-select
            v-model="landingSelectValue"
            label="Choose landing page"
            data-intercom-target="create_campaign.select.landing_page"
            :items="[
              {
                text: stripProtocol(value.business.website),
                value: stripProtocol(value.business.website)
              },
              { text: 'Custom', value: 'custom' }
            ]"
            class="mb-2"
            @change="selectLandingUrl"
          ></v-select>
          <v-text-field
            v-show="displayRedirectInput"
            label="Insert link"
            persistent-hint
            hint="The website URL the customers are referred to"
            v-model="redirectUrl"
            v-validate="{ required: true, business_website_match: true }"
            data-vv-scope="redirect_url"
            data-vv-name="redirect_url"
            data-vv-as="URL'en"
            :error-messages="errors.collect('redirect_url.redirect_url')"
          ></v-text-field>
          <!-- Deep linking -->
          <v-switch
            v-model="value.allow_deep_linking"
            label="Option to link to sub page"
          ></v-switch>
          <!-- Avanceret google analytics tracking -->
          <v-switch
            v-model="googleAnalyticsEnabled"
            label="Advanced Google Analytics tracking"
          ></v-switch>
          <div xs12 sm12 md6 lg6 v-show="googleAnalyticsEnabled">
            <div class="mb-1">
              The purpose of UTM parameters is to give Google Analytics (and YOU)
              a broader understanding of the traffic, which is linket to a given website.
            </div>
            <v-text-field
              v-model="utmSource"
              label="UTM source"
              :counter="64"
              v-validate="'min:1|max:64'"
              data-vv-scope="utm"
              data-vv-name="utm_source"
              data-vv-as="The text"
              :error-messages="errors.collect('utm.utm_source')"
              class="mb-3"
            ></v-text-field>
            <v-text-field
              v-model="utmMedium"
              label="UTM medium"
              :counter="64"
              v-validate="'min:1|max:64'"
              data-vv-scope="utm"
              data-vv-name="utm_medium"
              data-vv-as="The text"
              :error-messages="errors.collect('utm.utm_medium')"
              class="mb-3"
            ></v-text-field>
            <v-text-field
              v-model="utmCampaign"
              label="UTM campaign"
              :counter="64"
              v-validate="'min:1|max:64'"
              data-vv-scope="utm"
              data-vv-name="utm_campaign"
              data-vv-as="The text"
              :error-messages="errors.collect('utm.utm_campaign')"
              class="mb-3"
            ></v-text-field>
          </div>
        </div>
      </v-stepper-content>
    </v-stepper>
    <div v-if="allCompleted" class="text-center pb-4">
      <v-btn color="success" data-intercom-target="create_campaign.btn.step1_next" medium @click="$emit('next', true)">Continue</v-btn>
    </div>
  </v-form>
</template>
<script>
import { mapState, mapActions } from "vuex";
import InformationDialog from "../../../dialogs/InformationDialog";
import ChannelCategorySelect from "../../../selects/ChannelCategorySelect";
import local_numbers from "@/helpers/filters/local_numbers";

export default {
  components: { InformationDialog, ChannelCategorySelect },
  filters: { local_numbers },
  props: {
    value: {
      required: true
    },
    business: {
      type: [Object, null],
      required: false,
      default: null
    }
  },
  data: () => ({
    step: 1,
    followerItems: [
      0,
      5000,
      10000,
      25000,
      50000,
      100000
      // 500000,
      // 1000000
    ],
    categories: [],
    redirectUrl: "",
    utmMedium: null,
    utmCampaign: null,
    utmSource: null,
    googleAnalyticsEnabled: false,
    landingSelectValue: null,
    displayRedirectInput: false
  }),
  computed: {
    ...mapState("core/auth", ["user"]),
    stepVisibilityCompleted() {
      return this.value.hidden !== null;
    },
    stepMinimumFollowersCompleted() {
      return this.value.min_followers !== null;
    },
    stepCategoryCompleted() {
      return this.value.category !== null && this.value.category.id !== null;
    },
    stepLandingCompleted() {
      if (!this.googleAnalyticsEnabled) {
        return !!this.value.url && this.value.allow_deep_linking !== null;
      } else {
        return (
          !!this.value.url &&
          this.value.allow_deep_linking !== null &&
          this.value.utm_medium !== null &&
          this.value.utm_campaign !== null
        );
      }
    },
    allCompleted() {
      return (
        this.stepVisibilityCompleted &&
        this.stepMinimumFollowersCompleted &&
        this.stepCategoryCompleted &&
        this.stepLandingCompleted
      );
    },
    orderedCategories() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.categories.sort(first => {
        return first.id === this.value.business.channel_category.id ? -1 : 1;
      });
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        if (!newValue) {
          return;
        }
        if (!oldValue || newValue.id !== oldValue.id) {
          this.redirectUrl = this.value.url;
          this.utmMedium = this.value.utm_medium;
          this.utmCampaign = this.value.utm_campaign;
          this.utmSource = this.value.utm_source;
        }
      }
    },
    redirectUrl(value) {
      if (value) {
        this.$validator.validateScopes("redirect_url").then(() => {
          if (this.errors.collect("redirect_url.redirect_url").length === 0) {
            if (
              this.redirectUrl.includes("http://") ||
              this.redirectUrl.includes("https://")
            ) {
              this.value.url = this.redirectUrl;
            } else {
              this.value.url = `http://${this.redirectUrl}`;
            }
          } else {
            this.value.url = null;
          }
        });
      } else {
        this.value.url = null;
      }
    },
    googleAnalyticsEnabled(value) {
      if (!value) {
        this.utmMedium = null;
        this.utmCampaign = null;
        this.utmSource = null;
      }
    },
    utmMedium() {
      this.validateUtmFields();
    },
    utmCampaign() {
      this.validateUtmFields();
    },
    utmSource() {
      this.validateUtmFields();
    }
  },
  methods: {
    ...mapActions("core", ["getChannelCategories"]),
    validateUtmFields() {
      this.$validator.validateScopes("utm").then(() => {
        if (this.errors.collect("utm.utm_medium").length === 0) {
          this.value.utm_medium = this.utmMedium;
        } else {
          this.value.utm_medium = "";
        }

        if (this.errors.collect("utm.utm_campaign").length === 0) {
          this.value.utm_campaign = this.utmCampaign;
        } else {
          this.value.utm_campaign = "";
        }

        if (this.errors.collect("utm.utm_source").length === 0) {
          this.value.utm_source = this.utmSource;
        } else {
          this.value.utm_source = "";
        }
      });
    },
    selectLandingUrl(event) {
      if (event === "custom") {
        this.redirectUrl = `${this.value.business.website}/`;
        this.displayRedirectInput = true;
      } else {
        this.redirectUrl = event;
        this.displayRedirectInput = false;
      }
    },
    stripProtocol(url) {
      return url.replace(/(^\w+:|^)\/\//, "");
    }
  },
  created() {
    if (!this.value.category) {
      this.value.category = {
        id: null
      };
    }
    this.getChannelCategories().then(response => {
      this.categories = response.data.channelCategories;
      if (!this.value.category) {
        this.value.category.id = this.value.business.channel_category.id;
        this.$forceUpdate();
      }
    });
    // set base landing url
    if (this.value.url) {
      const businessUrl = this.stripProtocol(this.value.business.website);
      const valueUrl = this.stripProtocol(this.value.url);
      if (businessUrl === valueUrl) {
        this.landingSelectValue = valueUrl;
        this.displayRedirectInput = false;
        this.redirectUrl = valueUrl;
      } else {
        this.landingSelectValue = "custom";
        this.displayRedirectInput = true;
        this.redirectUrl = valueUrl;
      }
    }
    // set base deep linking state to true
    if (this.value.allow_deep_linking === null) {
      this.value.allow_deep_linking = true;
    }
    // enable google analitycs if has utm fields
    if (
      this.value.utm_medium ||
      this.value.utm_campaign
    ) {
      this.googleAnalyticsEnabled = true;
    }
    var _this = this;
    // TODO: Move to mixin
    this.$validator.extend("business_website_match", {
      getMessage() {
        return "URL has to match the company website URL";
      },
      validate(value) {
        let campaignUrl = value
          .toLowerCase()
          .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
        let businessUrl = "";
        // If agency use business from value
        if (_this.value.business) {
          businessUrl = _this.value.business.website
            .toLowerCase()
            .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
        } else {
          businessUrl = _this.user.business.website
            .toLowerCase()
            .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
        }

        return campaignUrl.indexOf(businessUrl) !== -1;
      }
    });

    window.Intercom('trackEvent', 'create_campaign.step1');
  }
};
</script>
