<template>
  <v-form ref="form">
    <v-stepper v-model="step" vertical class="elevation-0">
      <v-stepper-step
        step="1"
        :complete="stepProductDescriptionCompleted"
        :color="stepProductDescriptionCompleted ? 'success' : 'primary'"
        @click="step = 1"
        class="cursor-pointer"
      >
        <div class="cursor-pointer" @click="step = 1">
          <div>Describe your product and target demographic</div>

          <small class="font-italic"
            >Type between {{ productDescription.min }} and
            {{ productDescription.max }} characters</small
          >
        </div>
      </v-stepper-step>
      <v-stepper-content step="1">
        <div class="mb-3">
          <p>
            Briefly and concisely desribe your product. I.e.:
          </p>
          <ul>
            <li>The product is aimed at women between the ages of 15 and 35</li>
            <li>The product is only available between the 1st and 31st of October</li>
            <li>The product is limited edition</li>
            <li>The product is a best seller</li>
          </ul>
        </div>

        <simple-rich-text-input
          v-model="value.product_description"
          :height="200"
          :minlength="productDescription.min"
          :maxlength="productDescription.max"
          :textStrength="productDescriptionProgressIndicator"
        ></simple-rich-text-input>
        <div class="text-center mt-3 mb-4">
          <v-btn
            color="primary"
            :disabled="!stepProductDescriptionCompleted"
            medium
            @click="step = step + 1"
            >Continue</v-btn
          >
        </div>
      </v-stepper-content>

      <v-stepper-step
        step="2"
        :complete="stepPromotionMaterialDescriptionCompleted"
        :color="
          stepPromotionMaterialDescriptionCompleted ? 'success' : 'primary'
        "
        @click="step = 2"
        class="cursor-pointer"
      >
        <div class="cursor-pointer" @click="step = 2">
          <div>What does the influencer get?</div>
          <small class="font-italic"
            >Type between {{ promotionMaterialDescription.min }} and
            {{ promotionMaterialDescription.max }} characters</small
          >
        </div>
      </v-stepper-step>
      <v-stepper-content step="2">
        <div class="mb-3">
          <p>
            What does the influencer get for promoting the campaign? I.e.:
          </p>
          <ul>
            <li>You will receive 1 product worth 100 kr</li>
            <li>You can choose between 10 products (or a 500 DKK gift card)</li>
            <li>You'll receive a login to showcase the system</li>
          </ul>
        </div>

        <simple-rich-text-input
          v-model="value.promotion_material_description"
          :height="200"
          :minlength="promotionMaterialDescription.min"
          :maxlength="promotionMaterialDescription.max"
          :textStrength="promotionMaterialDescriptionProgressIndicator"
        ></simple-rich-text-input>
        <div class="text-center mt-3 mb-4">
          <v-btn color="black" text @click="step = step - 1">Back</v-btn>
          <v-btn
            color="primary"
            :disabled="!stepPromotionMaterialDescriptionCompleted"
            medium
            @click="step = step + 1"
            >Continue</v-btn
          >
        </div>
      </v-stepper-content>

      <v-stepper-step
        step="3"
        :complete="stepRecommendationsDescriptionCompleted"
        :color="stepRecommendationsDescriptionCompleted ? 'success' : 'primary'"
        @click="step = 3"
        class="cursor-pointer"
      >
        <div class="cursor-pointer" @click="step = 3">
          <div>Recommendations for promotion</div>
          <small class="font-italic"
            >Type between {{ recommendationsDescription.min }} and
            {{ recommendationsDescription.max }} characters</small
          >
        </div>
      </v-stepper-step>
      <v-stepper-content step="3">
        <div class="mb-3">
          <p>
            Help the influencer create the best possible promotional content by
            giving them selling points they can use when they promote your campaign.
            I.e.:
          </p>
          <ul>
            <li>Order before wednesday, and we ship monday</li>
            <li>Free shipping over 500 kr.</li>
            <li>These 5 products are our best sellers</li>
            <li>We have 1000 positive reviews on Trustpilot</li>
            <li>100% vegan, environmentally friendly etc. </li>
          </ul>
        </div>

        <simple-rich-text-input
          v-model="value.recommendations_description"
          :height="200"
          :minlength="recommendationsDescription.min"
          :maxlength="recommendationsDescription.max"
          :textStrength="recommendationsDescriptionProgressIndicator"
        ></simple-rich-text-input>
        <div class="text-center mt-3 mb-4">
          <v-btn color="black" text @click="step = step - 1">Back</v-btn>
          <v-btn
            color="primary"
            :disabled="!stepRecommendationsDescriptionCompleted"
            medium
            @click="step = step + 1"
            >Continue</v-btn
          >
        </div>
      </v-stepper-content>
      <v-stepper-step
        step="4"
        :complete="stepDescriptionCompleted"
        :color="stepDescriptionCompleted ? 'success' : 'primary'"
        @click="step = 4"
        class="cursor-pointer"
      >
        <div class="cursor-pointer" @click="step = 4">
          <div>Describe the campaign in general terms</div>
          <small class="font-italic"
            >Type between {{ description.min }} and
            {{ description.max }} characters</small
          >
        </div>
      </v-stepper-step>
      <v-stepper-content step="4">
        <div class="mb-3">
          <p>
            What is the focus and goal of this campaign? I.e.:
          </p>
          <ul>
            <li>What is the goal of the campaign</li>
            <li>Why should the influencer choose the campaign</li>
            <li>Why do you sell the product?</li>
            <li>Have you previously had success with influencers?</li>
            <li>Motivation for influencers (sizeable commission for
               top influencers or bonus products etc.)</li>
          </ul>
        </div>
        <simple-rich-text-input
          v-model="value.description"
          :height="200"
          :minlength="description.min"
          :maxlength="description.max"
          :textStrength="descriptionProgressIndicator"
        ></simple-rich-text-input>
      </v-stepper-content>
    </v-stepper>
    <div v-if="allCompleted" class="text-center pb-4">
      <v-btn class="mr-2" color="black" text @click="$emit('previous', true)"
        >Back</v-btn
      >
      <v-btn color="success" data-intercom-target="create_campaign.btn.step3_next" medium @click="$emit('next', true)">Continue</v-btn>
    </div>
  </v-form>
</template>
<script>
import SimpleRichTextInput from "../../../inputs/SimpleRichTextInput";

export default {
  components: { SimpleRichTextInput },
  props: {
    value: {
      required: true
    }
  },
  data: () => ({
    step: 1,
    productDescription: {
      min: 215,
      max: 550
    },
    promotionMaterialDescription: {
      min: 215,
      max: 550
    },
    recommendationsDescription: {
      min: 175,
      max: 550
    },
    description: {
      min: 215,
      max: 1000
    }
  }),
  computed: {
    stepProductDescriptionCompleted() {
      let length = this.stripHtmlTags(this.value.product_description).length;
      return (
        length >= this.productDescription.min &&
        length <= this.productDescription.max
      );
    },
    stepPromotionMaterialDescriptionCompleted() {
      let length = this.stripHtmlTags(this.value.promotion_material_description)
        .length;
      return (
        length >= this.promotionMaterialDescription.min &&
        length <= this.promotionMaterialDescription.max
      );
    },
    stepRecommendationsDescriptionCompleted() {
      let length = this.stripHtmlTags(this.value.recommendations_description)
        .length;
      return (
        length >= this.recommendationsDescription.min &&
        length <= this.recommendationsDescription.max
      );
    },
    stepDescriptionCompleted() {
      let length = this.stripHtmlTags(this.value.description).length;
      return length >= this.description.min && length <= this.description.max;
    },
    allCompleted() {
      return (
        this.stepProductDescriptionCompleted &&
        this.stepPromotionMaterialDescriptionCompleted &&
        this.stepRecommendationsDescriptionCompleted &&
        this.stepDescriptionCompleted
      );
    },
    productDescriptionProgressIndicator() {
      return [
              {min: 0, max: (this.productDescription.min - 1), class: 'error--text', text: "Write at least " + this.productDescription.min + " characters - we know you can do it! 🙌🏻"},
              {min: this.productDescription.min, max: 384, class: 'warning--text', text: "Thats better, but if you reach 385, it's starting to look good! 😉"},
              {min: 385, max: this.productDescription.max, class: 'success--text', text: "Well done! You've reached our recomendation 🚀"},
              {min: this.productDescription.max, max: 1000000, class: 'error--text', text: "You can only use "+ this.productDescription.max +" characters - delete a few, and you're back on track 🥳"},
      ];
    },
    promotionMaterialDescriptionProgressIndicator() {
      return [
        {min: 0, max: this.promotionMaterialDescription.min - 1, class: 'error--text', text: "Write at least  " + this.promotionMaterialDescription.min + " characters - we know you can do it! 🙌🏻"},
        {min: this.promotionMaterialDescription.min, max: 384, class: 'warning--text', text: "Thats better, but if you reach 385, it's starting to look good! 😉"},
        {min: 385, max: this.promotionMaterialDescription.max, class: 'success--text', text: "Well done! You've reached our recomendation 🚀"},
        {min: this.promotionMaterialDescription.max, max: 1000000, class: 'error--text', text: "You can only use "+ this.promotionMaterialDescription.max +" characters - delete a few, and you're back on track 🥳"},
      ];
    },
    recommendationsDescriptionProgressIndicator() {
      return [
        {min: 0, max: this.recommendationsDescription.min - 1, class: 'error--text', text: "Write at least " + this.recommendationsDescription.min + " characters - we know you can do it! 🙌🏻"},
        {min: this.recommendationsDescription.min, max: 384, class: 'warning--text', text: "Thats better, but if you reach 385, it's starting to look good! 😉"},
        {min: 385, max: this.recommendationsDescription.max, class: 'success--text', text: "Well done! You've reached our recomendation 🚀"},
        {min: this.recommendationsDescription.max, max: 1000000, class: 'error--text', text: "You can only use "+ this.recommendationsDescription.max +" characters - delete a few, and you're back on track 🥳"},
      ];
    },
    descriptionProgressIndicator() {
      return [
        {min: 0, max: this.description.min-1, class: 'error--text', text: "Write at least " + this.description.min + " characters - we know you can do it! 🙌🏻"},
        {min: this.description.min, max: 384, class: 'warning--text', text: "Thats better, but if you reach 385, it's starting to look good! 😉"},
        {min: 385, max: this.description.max, class: 'success--text', text: "Well done! You've reached our recomendation 🚀"},
        {min: this.description.max, max: 1000000, class: 'error--text', text: "You can only use "+ this.description.max +" characters - delete a few, and you're back on track 🥳"},
      ];
    }
  },
  methods: {
    stripHtmlTags(value) {
      let tmp = document.createElement("DIV");
      tmp.innerHTML = value;
      return (tmp.textContent || tmp.innerText || "").replace(/  +/g, " ");
    }
  },
  created() {
    window.Intercom('trackEvent', 'create_campaign.step3');
  }
};
</script>
